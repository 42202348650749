@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap')

body
  font-family: Roboto, sans-serif
  background: #fff
  margin: 0

#preview
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100vh

  background: #e0e0e000
  padding-top: 100vh

  pointer-events: none
  touch-action: none

  transition: background ease .6s, padding-top ease-out .6s

  &.active
    padding-top: 0
    background: #e0e0e0e0
    pointer-events: all
    touch-action: auto

  div
    img
      position: relative
      display: block
      height: 50vh

      border-radius: .6em

    span
      font-size: 1.2em

main
  width: 40em
  max-width: 100%
  margin: 0 auto
  counter-reset: li

  h1
    font-size: 3em
    margin-bottom: 0

  span
    display: block
    position: relative
    top: -0.4em
    margin-left: .2em

  li
    counter-increment: li
    display: list-item
    padding-left: 0.4em

    &::marker
      display: list-item
      content: counter(li) ". "

  #file-upload-li
    &::marker
      content: "(" counter(li) ".)"

    input
      margin-left: 0.5em

  .logo
    display: block
    width: 60%
    margin: 3em auto

  a
    text-decoration: none
    color: #008000
    cursor: pointer

  code
    background: #eee
    padding: .1em .3em .2em
    border-radius: .4em

  .icon
    position: relative
    bottom: -.05em
    width: 1em

  .gallery
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-column-gap: 1em

    margin-top: 2em

    div
      position: relative

      background: #000
      border: .1em solid black
      border-radius: .4em
      overflow: hidden

      img
        display: block
        width: 100%

        cursor: pointer
        user-select: none

      &:before, &:after
        display: block
        position: absolute
        width: 100%

        pointer-events: none
        touch-action: none
        cursor: pointer
        opacity: 0

        transition: opacity ease .4s

      &:before
        content: ""
        top: 0
        height: 100%

        background: #e0e0e0

      &:hover:before
        opacity: .7

      &:after
        top: 50%

        content: "Click to zoom"
        text-align: center

        transform: translateY(-50%)

      &:hover:after
        opacity: 1

  canvas
    display: block
    width: 100%
    background: #00000000
